.table-body-list-container {
  width: 100%;
  height: 90%;
  position: relative;
  overflow: hidden;
  user-select: none;
  -webkit-user-select:none; 
    -moz-user-select:none; 
    -ms-user-select:none; 
}

.table-body-list {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  cursor: pointer;
  list-style: none;
  transition: 0.5s;
  transform: translateZ(0);
}

.table-body-container {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}


.table-body {
  margin: 0 2.5%;
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.table-body-header {
  list-style: none;
  display: flex;
  justify-content: space-between;
  background-color: rgb(104, 195, 238);
  height: 6%;
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.table-day {
  display: flex;
  flex-wrap: wrap;
  width: 14.28%;
}

.table-day-date {
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-day-weekday {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}


.lesson-table {
  height: 93.5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
}

.lesson-morning {
  height: 35.2%;
  width: 100%;
}

.lesson-afternoon {
  height: 35.2%;
  width: 100%;
}

.lesson-night {
  height: 26.4%;
  width: 100%;
}

.lesson-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  list-style: none;
}

.lesson {
  width: 13%;
  margin: 0 0.64%;
  background-color: pink;
  border-radius: 0.5rem;
  padding: 0.5%;
  word-break: break-all;
  overflow: hidden;
}

.lesson span {
  color: #fff;
}

.lesson-none {
  background-color: transparent;
  font-size: 0;
}

.day-lesson-1 {
  height: 22%;
}

.day-lesson-2 {
  height: 47%;
}

.day-lesson-3 {
  height: 72%;
}

.day-lesson-4 {
  height: 97%;
}

.night-lesson-1 {
  height: 29.33%;
}

.night-lesson-2 {
  height: 62.67%;
}

.night-lesson-3 {
  height: 96%;
}

.color-1 {
    background-color: #FF446E;
}

.color-2 {
    background-color: #FF719A;
}

.color-3 {
    background-color: #FD7EBE;
}

.color-4 {
    background-color: #FF7449;
}

.color-5 {
    background-color: #ff8a67;
}

.color-6 {
    background-color: #FFA325;
}

.color-7 {
    background-color: #ffe14a;
}

.color-8 {
    background-color: #59e691;
}

.color-9 {
    background-color: #4addd1;
}

.color-10 {
    background-color: #12bdbd;
}

.color-11 {
    background-color: #5dc4ff;
}

.color-12 {
    background-color: #3aabec;
}

.color-13 {
    background-color: #3281e9;
}

.color-14 {
    background-color: #1969d1;
}

.color-15 {
    background-color: #8252fd;
}

.color-16 {
    background-color: #c662fc;
}

.color-17 {
    background-color: #f3aeae;
}

.color-18 {
    background-color: #cc7150;
}
