.table-header {
  width: 50%;
  height: 6%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-button {
  display: block;
  height: 80%;
  width: 15%;
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.068);
  cursor: pointer;
}

.header-button:active {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.103);
}

.header-week-select {
  width: 30%;
  -webkit-appearance: none;
  padding-left: 7%;
}

@media screen and (max-width:700px) {
  .table-header {
    width: 80%;
  }
}