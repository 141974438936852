.table {
  width: 63rem;
  height: 70rem;
  background-color: rgb(234, 249, 255);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 1rem;
  box-shadow: 0 2px 10px rgb(0 0 0 / 14%);
  align-items: center;
  overflow: hidden;
}

.lesson-info-container {
  top: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  transition: transform 0.4s ease;
}

.lesson-info {
  width: 50%;
  height: 60%;
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 1rem;
  overflow: hidden;
}

.lesson-info-close {
  height: 6%;
  width: 100%;
  font-size: 2rem;
  background-color: rgb(255, 176, 176);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.lesson-info-list {
  height: 86%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  margin-top: 5%;
}

.lesson-info-list::-webkit-scrollbar {
  display: none;
}

.lesson-info-attr {
  width: 90%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 5%;
}

.attr-name {
  width: 30%;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: rgb(22, 95, 206);
}

.attr-name svg {
  width: 2rem;
  height: 2rem;
  vertical-align: -0.3rem;
  margin-right: 10%;
}

.attr-value {
  width: 70%;
  font-size: 1.5rem;
  flex-shrink: 0;
  color: rgb(92, 92, 92);
}

.icon-xiajiantou {
  font-size: 2rem;
}

@media screen and (max-width:700px) {
  .table {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
  }

  .lesson-info{
    width: 75%;
  }
}