* {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

html,body {
  background-color: rgb(173, 229, 255);
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

.icon {
  width: 1rem;
  height: 1rem;
  vertical-align: -0.15rem;
  fill: currentColor;
  overflow: hidden;
}